input#chat-snack-input {
  color: black;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.send_msg_btn.MuiButton-containedPrimary {
  background-color: #187dcd;
  color: white;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #187dcd !important;
}
